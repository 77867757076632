<template>
    <v-main>
        <section id="hero">
            <v-row no-gutters>
                <v-container
                    id="personal"
                    tag="section"
                >
                    <div style="text-align: center">
                        <img
                            src="/img/LogoLifePlus.png"
                            alt=""
                            style="width: 100px"
                        >
                    </div>

                    <v-row
                        justify="center"
                    >
                        <v-col
                            cols="12"
                            style="max-width: 978px"
                        >
                            <v-card
                                class="v-card-profile v-card--material pa-3"
                            >
                                <div class="d-flex grow flex-wrap">
                                    <v-card-text>
                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
                                        </h4>
                                        <p>
                                            Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года свободно, своей волей и в своем интересе выражаю свое безусловное согласие на обработку моих персональных данных администрации сайта lifeplus.tv
                                        </p>
                                        <p>
                                            Персональные данные — любая информация, относящаяся к определенному или определяемому на основании такой информации физическому лицу.
                                        </p>
                                        <p>
                                            Настоящее Согласие выдано мною на обработку следующих персональных данных:
                                            <br>
                                            - Имя
                                            <br>
                                            - Фамилия
                                            <br>
                                            - Телефон
                                            <br>
                                            - Email
                                            <br>
                                            - партнерский номер LR Health & Beauty
                                            <br>
                                            и другие данные, предоставяемые мною в Профиле на Моей странице сайта lifeplus.tv
                                        </p>
                                        <p>
                                            Согласие дано Оператору для совершения следующих действий с моими персональными данными с использованием средств автоматизации и/или без использования
                                            таких средств: сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, а также осуществление любых
                                            иных действий, предусмотренных действующим законодательством РФ как неавтоматизированными, так и автоматизированными способами.
                                        </p>
                                        <p>
                                            Данное согласие дается Оператору для обработки моих персональных данных в следующих целях:
                                            <br>
                                            - предоставление мне услуг/работ
                                            <br>
                                            - направление мне уведомлений, касающихся предоставляемых услуг/работ
                                            <br>
                                            - подготовка и направление ответов на мои запросы
                                            <br>
                                            - направление мне информации, в том числе рекламной, о мероприятиях/товарах/услугах/работах Оператора
                                            <br>
                                            - партнерский номер LR Health & Beauty
                                            <br>
                                        </p>
                                        <p>
                                            Настоящее согласие действует до момента его отзыва путем направления соответствующего уведомления на электронный адрес
                                            lifeplus.tv.relife@gmail.com.
                                        </p>
                                        <p>
                                            В случае отзыва мною согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без моего согласия при наличии оснований,
                                            указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 26.06.2006 г.
                                        </p>
                                    </v-card-text>
                                </div>
                                <v-divider/>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </section>
    </v-main>
</template>

<script>
export default {
name: "Personal"
}
</script>

<style scoped>

</style>